/*
  Global styles
*/

@import "../stylesheets/fonts";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

:root {
  --modal-width: 75vw;
}

* {
  font-family: "Subjectivity", sans-serif !important;

  body {
    font-family: "Subjectivity", sans-serif !important;
  }

  p {
    font-family: "Subjectivity", sans-serif !important;
  }

  h1 {
    font-family: "Subjectivity", sans-serif !important;
    font-weight: 800 !important;
  }

  h2 {
    font-family: "Subjectivity", sans-serif !important;
    font-weight: 700 !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header-spacer {
  height: 4em;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-even {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.flex-col {
  flex-direction: column;
}

.btn {
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.6vw;
}
